<template>
  <div>
    <v-btn
      v-if="dnsMismatch"
      elevation="0"
      outlined
      x-small
      color="error"
      class="mr-4"
      :loading="loading"
      @click="loadModal"
    >
      <v-icon size="24">$alertwarning</v-icon>
      <span class="text--heading p-4 font-weight-bold"
        >{{ $t("message.dnsPropagationInProgress") }}</span
      >
    </v-btn>
    <v-btn
      v-else-if="!dnsMismatch && instance.getUserPrivileges('general.change_domain')"
      elevation="0"
      outlined
      x-small
      color="primary"
      class="mr-4"
      :loading="loading"
      @click="loadModal"
    >
      <span class="text--heading p-4 font-weight-bold">{{ $t("button.setDomain.changeDomain") }}</span>
    </v-btn>
    <v-dialog
      ref="dialog"
      v-model="isOpen"
      transition="custom-dialog-transition"
    >
      <div class="card-overlay" @click="isOpen = !isOpen" />
      <v-card style="width: 560px">
        <v-card-title class="pb-6 flex justify-space-between align-center">
          <h4 class="font-weight-light">{{ $t('heading.instance.modal.setDomain.title') }}</h4>
          <v-btn icon x-small @click="isOpen = false">
            <v-icon size="24">$close</v-icon>
          </v-btn>
        </v-card-title>
        <template v-if="step === 0">
          <v-card-text>
            <v-form ref="form" @submit.prevent="nextStep">
              <v-expansion-panels v-model="panelOpen">
                <v-expansion-panel elevation="0">
                  <v-expansion-panel-header
                    class="h6 text--heading font-weight-bold"
                  >
                    {{ $t('heading.instance.modal.setDomain.set.domain.title') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ $t('form.label.domainName') }}
                    <a class="float-right info--text" v-if="orderDomainLink" target="_blank" :href="orderDomainLink">
                      {{ $t('button.domain.order') }}
                    </a>
                    <v-text-field
                      v-if="!panelOpen"
                      class="mt-2"
                      outlined
                      placeholder="mydomain.com"
                      v-model="clientDomainName"
                      hide-details=""
                      validate-on-blur
                      :rules="[
                        (v) => !!v || $t('validation.required', {
                          field: $t('form.label.domainName')
                        }),
                        (v) => domainRegex.test(v) || $t('validation.valid', {
                          field: $t('form.label.domainName')
                        })
                      ]"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="freeSubdomains.length > 0">
                  <v-expansion-panel-header
                    class="h6 text--heading font-weight-bold"
                  >
                    {{ $t('heading.instance.modal.setDomain.set.subdomain.title') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-if="panelOpen === 1"
                      class="flex flex-row align-center"
                      style="display: flex"
                    >
                      <v-text-field
                        class=""
                        outlined
                        placeholder="mydomain"
                        v-model="freeDomainName"
                        hide-details=""
                        validate-on-blur
                        max-width="250px"
                        :rules="[(v) => !!v || $t('validation.required', {
                          field: $t('general.subdomain')
                        })]"
                      />
                      <span class="mx-2 mb-6">.</span>
                      <v-select
                        outlined
                        :items="freeSubdomains"
                        v-model="freeDomainAffix"
                        hide-details=""
                        validate-on-blur
                        max-width="250px"
                        :rules="[(v) => !!v || $t('validation.required', {
                          field: $t('form.label.domain')
                        })]"
                        :menu-props="{
                          offsetY: true,
                          nudgeBottom: '8px',
                          closeOnContentClick: false,
                          contentClass:
                            'custom-dropdown-select custom-dropdown-select--onboarding',
                        }"
                      />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </v-card-text>

          <v-card-actions class="d-flex flex-column pb-4 px-8 pt-6">
            <v-btn
              x-large
              elevation="0"
              color="primary"
              block
              :loading="submitting"
              @click="nextStep"
            >
              {{ $t('button.setDomain.useThisDomain') }}
            </v-btn>
            <v-btn
              x-large
              elevation="0"
              class="mx-0 mt-2"
              color="gray"
              text
              block
              @click="isOpen = false"
            >
              <span class="p-1 font-weight-light gray--text text--darken-1">
                {{ $t('button.cancel') }}
              </span>
            </v-btn>
          </v-card-actions>
        </template>
        <template v-else-if="step === 1">
          <v-card-text>
            <div class="domain-container">
              <span class="p-3">
                {{ $t('heading.instance.modal.setDomain.config.subtitle') }}
                <span class="text--heading font-weight-600">
                  {{ instance.domain }}
                </span>
              </span>

              <v-btn
                color="primary"
                x-small
                outlined
                elevation="0"
                @click="step = 0"
                v-if="instance.getUserPrivileges('general.change_domain')"
              >
                {{ $t('button.change') }}
              </v-btn>
            </div>

            <h6 class="text--heading font-weight-bold mb-4">
              {{ $t('heading.instance.modal.setDomain.config.title') }}
            </h6>

            <div class="domain-info">
              <v-tabs v-model="domainInfoTab" color="primary" class="mb-4">
                <v-tab>{{ $t('heading.instance.modal.setDomain.config.tab.dnsRecords.title') }}</v-tab>
                <v-tab v-if="instance.service_has_dns">{{ $t('heading.instance.modal.setDomain.config.tab.nameservers.title') }}</v-tab>
              </v-tabs>
              <div v-if="domainInfoTab === 0">
                <ol>
                  <li>{{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.first") }}</li>
                  <li>
                    {{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.second") }}
                    <ol>
                      {{ $t('general.name') }}:
                      <strong>{{ instance.domain }}</strong>
                      ({{ $t('general.or') }}
                      <strong>@</strong
                      >)
                      <br />{{ $t('general.type') }}:
                      <strong>A</strong>
                      <br />{{ $t('general.address') }}:
                      <strong>{{ ipAddress }}</strong>
                      <br />---
                      <br />{{ $t('general.name') }}:
                      <strong>www.{{ instance.domain }}</strong>
                      <br />{{ $t('general.type') }}:
                      <strong>CNAME</strong>
                      <br />{{ $t('general.address') }}:
                      <strong>{{ instance.domain }}</strong>
                    </ol>
                  </li>
                  <li>
                    {{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.third") }}
                  </li>
                </ol>
              </div>
              <div v-if="domainInfoTab === 1">
                <ol>
                  <li>{{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.first") }}</li>
                  <li>
                    {{ $t("heading.instance.modal.setDomain.config.tab.nameservers.list.second") }}
                    <ol v-for="(ns, index) in nameservers" :key="index">
                      <strong>{{ ns }}</strong>
                    </ol>
                  </li>
                  <li>
                    {{ $t("heading.instance.modal.setDomain.config.tab.dnsRecords.list.third") }}
                  </li>
                </ol>
              </div>
            </div>
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/apis/Api";
export default {
  data: function () {
    return {
      isOpen: false,
      panelOpen: 0,
      dnsMismatch: false,
      step: 0,
      loading: false,
      clientDomainName: "",
      freeDomainAffix: "",
      freeDomainName: "",
      domainInfoTab: 0,
      freeSubdomains: [],
      submitting: false,
      ipAddress: "",
      nameservers: [],
      domainRegex: /[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,9}(:[0-9]{1,5})?(\/[a-zA-Z0-9_~-]+)*$/
      //orderDomainLink: "",
    };
  },
  props: {
    instance: Object,
  },
  computed: {
		orderDomainLink() {
			return this.$store.state.home.user.order_domain_link;
		},
	},
  methods: {
    loadModal() {
      this.loading = true;
      return Api.cached()
        .get(`/instances/${this.instance.id}/change-domain/options`)
        .then((response) => {
          this.freeSubdomains = response.data.data.subdomains;
          this.freeDomainAffix = this.freeSubdomains[0];
          this.ipAddress = response.data.data.ip_address;
          this.nameservers = response.data.data.nameservers;
          //this.orderDomainLink = response.data.data.order_domain_link;
          this.isOpen = true;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkDnsPropagation() {
      if (!this.instance.domain_has_dns_zone) {
        return;
      }

      this.loading = true;
      return Api.dns()
        .resolveARecord(this.instance.domain)
        .then((response) => {
          if (response != this.instance.host_ip_address) {
            this.dnsMismatch = true;
            this.step = 1;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    nextStep() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.panelOpen === 0) {
        this.submitting = true;
        Api.put(`/instances/${this.instance.id}/change-domain/domain`, {
          domain: this.clientDomainName,
        })
          .then((response) => {
            this.checkDnsPropagation().then(() => {
              this.$emit("instance-published", response.data.data);
              this.nameservers = response.data.data.dns_nameservers;
              this.step = 1;
            });
          })
          .catch((error) => {
            this.$store.dispatch("addAlert", {
              success: false,
              errorMessage: Api.getErrorMessage(error),
            });
          })
          .finally(() => {
            this.submitting = false;
          });
      } else {
        this.submitting = true;
        Api.put(`/instances/${this.instance.id}/change-domain/subdomain`, {
          subdomain: this.freeDomainName,
          domain: this.freeDomainAffix,
        })
          .then((response) => {
            this.checkDnsPropagation().then(() => {
              this.$emit("instance-published", response.data.data);
              this.isOpen = false;
            });
          })
          .catch((error) => {
            this.$store.dispatch("addAlert", {
              success: false,
              errorMessage: Api.getErrorMessage(error),
            });
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
  },
  watch: {
    isOpen: function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  mounted() {
    this.checkDnsPropagation();
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  -webkit-line-clamp: unset;
}

.v-expansion-panel {
  border: 1px solid var(--v-gray-lighten2);

  &::before {
    display: none;
  }

  &::after {
    display: none;
  }

  &--active {
    border-color: var(--v-primary-base);
  }

  .v-expansion-panel-content::v-deep {
    .v-expansion-panel-content__wrap {
      padding-bottom: 0px;
    }
  }

  .v-expansion-panel-header {
    padding-left: 60px;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 1 !important;
      background: white;
      border-radius: 20px;
    }

    &::before {
      transition: border-color 0.24s ease, background-color 0.24s ease;
      width: 20px;
      height: 20px;
      border: 1px solid var(--v-gray-base);
    }

    &::after {
      width: 10px;
      height: 10px;
      z-index: 10;
      left: 29px;
    }

    &:hover {
      &::before {
        border-color: #07C07E;
      }
    }

    &--active {
      &:before {
        border-color: #07C07E;
        background-color: #07C07E;
      }
    }
  }
}

.domain-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--v-gray-lighten2);
  padding: 16px 16px;
  margin-bottom: 16px;
}

.domain-info {
  border-radius: 6px;
  border: 1px solid var(--v-gray-lighten2);
  padding: 6px 16px 16px 16px;
  background: #fafafa;
  margin-bottom: 24px;
}
</style>